/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

* {
  outline: none !important;
}
.ant-card{
  box-shadow: 0 4px 24px 0 #22292f1a;
  border-radius: 0.428rem !important;
  .ant-card-body{
    padding: 20px !important;
  }
}
.dark-layout{
  .ant-card{
    background-color: #0c1c33;
    border: none;
  }
}
.mb-card{
  margin-bottom: 26px !important;
}
.dark-layout .ant-table {
  background-color: #283046;
  color: #b4b7bd;
}

.dark-layout .ant-table-thead>tr>th {
  background: #283046;
  border-bottom: 0px solid #b4b7bd;
  color: #b4b7bd;
}

.ant-table-thead>tr>th {
  transition: background 0s ease !important;
}

.ant-table-tbody>tr>td {
  transition: background 0s !important;
}

.dark-layout .ant-modal-content,
.dark-layout .ant-modal-header {
  background-color: #04142d;
  color: white;
}

.dark-layout .ant-modal-header {
  border-bottom: 1px solid #b4b7bd;
}

.dark-layout .ant-empty-description {
  color: white;
}

.dark-layout .ant-modal-footer {
  padding: 10px 16px;
  background: transparent;
  border-top: 1px solid #b4b7bd;
  border-radius: 0 0 2px 2px;
}

.dark-layout .ant-modal-close-icon {
  color: white;
}

.dark-layout .ant-btn-default {
  background-color: #b4b7bd;
}

.dark-layout .ant-pagination-item a {
  color: white;
  // border: unset;
}

.ant-pagination-item {
  // border: 1px solid blue !important;
}

.dark-layout .ant-pagination-prev .ant-pagination-item-link {
  background-color: #283046;
  color: white;
  // border: 0px solid red !important;
}

.dark-layout .ant-pagination-next .ant-pagination-item-link {
  background-color: #283046;
  color: white;
  // border: 1px solid red !important;
}

.dark-layout td.ant-table-column-sort {
  background-color: #283046 !important;
}

.dark-layout .ant-select-selector {
  border-radius: 6px !important;
  background-color: inherit !important;
}

.dark-layout .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  background-color: transparent !important;
  font-weight: 550 !important;
  border: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-weight: 550 !important;
  border: none !important;
}

.dark-layout .ant-pagination-item {
  background-color: #283046;
}

.dark-layout .ant-picker-panel-container .ant-picker-panel {
  background: #283046;
  border-width: 0 0 1px 0;
  border-radius: 0;
  color: white;
}

.ant-picker-header {
  color: white;
}

.dark-layout .ant-picker-header button {
  color: white;
}

.dark-layout .ant-picker-header button:hover {
  color: #ccc;
}

.dark-layout .ant-picker-content th {
  color: white;
}

.dark-layout .ant-picker-cell {
  color: rgba(255, 255, 255, 0.3);
}

.dark-layout .ant-picker-cell-in-view {
  color: white;
}

.dark-layout .ant-picker-cell-disabled {
  color: rgb(191 191 191 / 25%);
}

.dark-layout .ant-picker-suffix {
  color: white;
}

.dark-layout .ant-table-tbody>tr.ant-table-row:hover>td {
  background: #283046;
  color: white;
}

.dark-layout .ant-table-cell-row-hover {
  background-color: #283046 !important;
  color: white;
}

.dark-layout .resourcesDataTable .ant-table-cell .ant-table-cell-row-hover {
  background-color: inherit !important;
  background: inherit !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.dark-layout .resourcesDataTable .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: inherit !important;
  background: inherit !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.dark-layout .resourcesDataTable .ant-table-cell-row-hover {
  background-color: #283046 !important;
  color: black !important;
}

.dark-layout .ant-table-cell-fix-left,
.dark-layout .ant-table-cell-fix-right {
  background: #283046;
  border-bottom: 0px solid #b4b7bd;
}

.dark-layout .ant-table-tbody>tr>td {
  border-bottom: 0px solid #b4b7bd;
}

.dark-layout .ant-table-expanded-row-fixed {
  background: #283046;
}

thead[class*='ant-table-thead'] th {
  background-color: #f3f2f7 !important;
}

.dark-layout thead[class*='ant-table-thead'] th {
  background-color: #343d55 !important;
  border-color: #3b4253;
}

.dark-layout td.ant-table-column-sort {
  background: none;
}

.basic-select-dark {
  .css-yk16xz-control {
    background-color: #283046;
    border-color: #404656;
  }

  .css-yk16xz-control &:focus {
    border-color: #7367f0;
    background-color: #283046;
  }

  .css-1pahdxg-control,
  .css-26l3qy-menu {
    background-color: #283046;
  }

  .css-dp5abo-control &:hover {
    border-color: #2176ff;
  }
}

.css-ambwqn-control,
.css-163z4n1-control,
.css-1nfsoi5-control,
.css-1k8hkrw-control {
  border-color: #2176ff !important;
}

.dark-bg .icdHOq {
  color: #d0d2d6;
  background-color: #283046;
}

.disable-pointer {
  pointer-events: none;
  opacity: 0.7;
}

.wrap-test {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-projects {
  width: 140px;
  cursor: pointer;
  color: #9c27b0;
  font-weight: bold;
}

.blue-color {
  color: #7367f0 !important;
}

.temp {
  .eGEGHg {
    height: 60vh;
  }

  .rdt_TableCell {
    min-height: 70px;
  }
}

.min-height-row {

  .fvRJRJ,
  .fhOify,
  .fXTNfa,
  .fuIyvJ {
    min-height: 200px;
  }
}

/* width */
table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.goback-button {
  position: sticky;
  top: 90px;
  z-index: 6;
  width: max-content;
  background: #ffffff;
  padding: 4px;
  border-radius: 5px;
}

.dark-layout .css-1wa3eu0-placeholder {
  color: #b7b4bf;
}

.goback-button-dark {
  position: sticky;
  top: 90px;
  z-index: 6;
  width: max-content;
  background: rgb(40, 48, 70);
  padding: 4px;
  border-radius: 5px;
}

.remarks-container {
  display: block;
  overflow-wrap: break-word;
  width: 150px;
}

.dark-background {
  background: rgb(40, 48, 70);
}

// .dark-layout .MuiSvgIcon-root {
//   color: #b4b7bd;
// }

.antTable-rowHeight {
  height: 315px;
}

.dark-layout .ant-table-tbody>tr>td {
  border-bottom: 2px solid rgba(195, 190, 190, 0.12);
}

.img-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.img-circle-portfolio {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.name-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 21px;
  font-weight: 800;
  align-items: center;
  background: rgb(24, 44, 76);
  display: flex;
  justify-content: center;

  &.name-circle_size {
    width: 40px;
    height: 40px;
    flex: unset !important;
    font-size: 1.15rem;
    position: relative;
  }
}

.manager_text_alignement {
  max-width: 140px;
}

.name-circle-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
  background: rgb(24, 44, 76);
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 5px;
  line-height: normal;
}

.name-circle-portfolio {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  align-items: center;
  background: rgb(24, 44, 76);
  text-align: center;
  padding: 3px;
  display: inline-block;
}

.divider {
  border-left: 1px solid black;
}

.img-circle-resume {
  width: 63px;
  height: 63px;
  border-radius: 50%;
}

.name-circle-resume {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 21px;
  font-weight: 800;
  align-items: center;
  background: rgb(24, 44, 76);
  display: flex;
  justify-content: center;
}

.dark-layout .ant-picker-content th {
  // color: black;
  font-weight: 500;
}

.ant-picker-calendar-header {
  padding: 12px 0;
  margin-left: 10px;
  justify-content: flex-start !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
  color: #8c8c8c;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.ant-picker-cell {
  border: 1px solid #ebebeb;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 0px solid blue !important;
  border-radius: 0;
  border-top-right-radius: 0;
}

.ant-picker-calendar {
  margin: 0;
  padding: 0;
  background: #fff;
  border-radius: 8px !important;
  border-top-right-radius: 0 !important;
  width: 1000px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
  display: none;
  border-radius: 8px;
}

.dark-layout .ant-picker-calendar-header .ant-select-arrow {
  color: white;
}

.style-higlight-na-data-fields {
  color: #ee4b2b;
}

.style-higlight-success {
  color: #1dd604;
}

.style-higlight-no-data-fields {
  color: #ff9019;
}

// App Bar Notification Dark Mode
.dark-layout {
  .UncontrolledDropdown {
    .list {
      background-color: #121212;
      color: #f9f9fa;
    }

    .Toolbar {
      background-color: #343d55 !important;
      color: rgba(249, 249, 250, 0.65);
    }

    .ListSubheader {
      color: #b4b7bd;
    }

    .MuiTypography-root {
      color: #b4b7bd !important;
    }

    .ListSubheader {
      background-color: #121212;
      border-bottom: 1px solid #697386;
    }
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #43464c !important;
  }
}

.ant-table-column-title {
  z-index: 0 !important;
}

.dark-layout .btn-down {
  background-color: rgb(40, 48, 70);
}

.dark-layout .reset-button {
  background: rgb(40, 48, 70);
  color: white;
}

.dark-layout .style-zoom-btn {
  background-color: rgb(40, 48, 70);
  color: white;
}

// dark mode color show more show less icons
.dark-layout .addOutlined,
.dark-layout .minimizeOutlined {
  color: #2176ff !important;
}

// All Modals & Pop-Up Style //
// Header & title
.ant-modal-header,
.MuiDialogTitle-root {
  background-color: #f8f8f8 !important;
  border-radius: 16px 16px 0 0 !important;
  padding: 1.5rem 2rem !important;
}

// Header Close buttons
.ant-modal-close-x {
  color: #000000 !important;
  height: 12.01px !important;
  width: 12.01px !important;
  padding-right: 4rem !important;
}

.ant-modal-close-x .anticon {
  border: 0.8px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
  padding: 5px !important;
}

// Body
// reactstrap body
// .modal-body {
//   padding-top: 2rem !important;
// }

// Antd body
.ant-modal-content {
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16) !important;
  border-radius: 16px !important;
}

.ant-modal-body {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

// Mui Body
.MuiDialogContent-root {
  padding-top: 2rem !important;
  padding-left: 26px !important;
}

//Footer
.ant-modal-footer {
  border-top: transparent !important;
  padding-bottom: 18px !important;
  padding-right: 1.75rem !important;
}

// Reactstrap footer
.modal-footer {
  border-top: transparent !important;
}

// Mui footer
.MuiDialogActions-root {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
  padding-right: 1.75rem !important;
}

// buttons
// antd buttons
.ant-btn-default {
  border-radius: 4px !important;
  border: 1px solid #82868b !important;
  color: white !important;
  background: #82868b !important;
}

.dark-layout .checkbox-label-style {
  font-size: 12.5px !important;
}

.checkbox-label-style {
  font-size: 12.5px !important;
}

.checkbox-project-style {
  margin-bottom: 0px !important;
}

.dark-layout .checkbox-project-style {
  margin-bottom: 0px !important;
}

.css-r4g31a-option {
  display: inline-flex !important;
}

.css-165g9jq-option {
  display: inline-flex !important;
}

.ant-btn-default:hover {
  background: #82868b !important;
  border: 1px solid #82868b !important;
}

.ant-btn .ant-btn-primary {
  border-radius: 4px !important;
}

.ant-btn .ant-btn-primary:hover {
  background: #1753b3 !important;
}

// Modals dark mode
.dark-layout .modal .modal-content {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 50%);
}

.dark-layout .modal-content .modal-header .modal-title {
  color: #d0d2d6 !important;
}

.dark-layout .ant-modal-header,
.dark-layout .MuiDialogTitle-root,
.dark-layout .MuiDialog-paper {
  background-color: #132847 !important;
}

.dark-layout .ant-modal-title,
.dark-layout .MuiDialogTitle-root,
.dark-layout .modal-content .modal-header .modal-title {
  color: white !important;
}

// dark mode hover effect
.dark-layout .css-tlfecz-indicatorContainer:focus,
.dark-layout .css-tlfecz-indicatorContainer:active,
.dark-layout .css-tlfecz-indicatorContainer:hover {
  color: #b4b7bd !important;
  background-color: transparent !important;
}

// dark mode antd pagination
.dark-layout .ant-table-pagination {
  margin: 0px !important;
  padding: 16px 0 !important;
  color: #b4b7bd !important;
  background-color: #283046 !important;
}

// dark mode scrollbar
.dark-layout ::-webkit-scrollbar {
  background: #051a38;
  height: 5px;
  width: 5px;
}

.dark-layout ::-webkit-scrollbar-thumb {
  background: white;
}

// dark mode multiselect icons
.dark-layout .css-1rhbuit-multiValue {
  color: white;
  background-color: #1976d2;
}

.dark-layout .resourcesDataTable .css-tlfecz-indicatorContainer {
  color: black !important;
}

.resourcesDataTable .css-tlfecz-indicatorContainer {
  color: black !important;
}

.dark-layout .resourcesDataTable .css-1okebmr-indicatorSeparator {
  background-color: #000000 !important;
}

.resourcesDataTable .css-1okebmr-indicatorSeparator {
  background-color: #000000 !important;
}

.dark-layout .css-xb97g8:hover {
  background-color: #90bbff;
  cursor: pointer;
}

.dark-layout .css-xb97g8:hover {
  background-color: #1976d2 !important;
}

.css-xb97g8:hover {
  cursor: pointer;
  background-color: hsl(0, 0%, 90%) !important;
}

.css-xb97g8:hover svg {
  fill: #1e1e1e !important;
  color: #1e1e1e !important;
}

.dark-layout .css-xb97g8:hover svg {
  fill: hsl(0, 0%, 90%) !important;
  color: hsl(0, 0%, 90%) !important;
}

.dark-layout ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

// dark mode pagination border & color
.dark-layout .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSizeMedium {
  border-color: white !important;
  border: 1px solid white !important;
  color: white !important;
  padding: 5px 10px !important;
}

.dark-layout .ant-select-selection-item {
  font-size: 11px !important;
}

.dark-layout .ant-pagination-options .ant-select-show-arrow .ant-select-selection-item {
  color: white !important;
}

.ant-select-selection-item {
  font-size: 11px !important;
  color: black !important;
}

.dark-layout .ant-select-selection-item {
  font-size: 11px !important;
  color: black !important;
}

.dark-layout .ant-select-dropdown {
  background-color: transparent !important;
}

.ant-select-dropdown {
  background-color: transparent !important;
}

.dark-layout .ant-select-arrow {
  color: #fff !important;
}

.ant-select-arrow {
  color: black !important;
}

.original:hover {
  box-shadow: 0 8px 25px -8px #82868b;
}

.ant-table-layout-fixed table {
  table-layout: auto !important;
}

.dark-layout .show-more-less {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #0460a9 !important;
  cursor: pointer !important;
}

.show-more-less {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #0460a9 !important;
  cursor: pointer !important;
}

.email-image-tooltip {
  height: 17px;
  margin-left: 3px;
  margin-top: -2px;
}

// SystemWide Input Style

.form-control {
  font-weight: 500 !important;
  border: 1px solid #98a0ae;
  font-size: 12px !important;
}

.form-control::placeholder {
  font-weight: 500;
  color: #7f8a9b !important;
}

.dark-layout .form-control {
  border: 1px solid white;
}

.form-control:focus::placeholder {
  color: #7f8a9b !important;
}

.dark-layout .form-control:focus::placeholder {
  color: white !important;
}

.form-control:focus,
.form-control:active,
.form-control:hover,
.dark-layout .form-control:focus,
.dark-layout .form-control:active,
.dark-layout .form-control:hover {
  border-color: #2176ff !important;
}

.MuiAutocomplete-clearIndicator {
  background-color: rgb(85, 55, 55) !important;
  color: white !important;
}

.MuiAutocomplete-popupIndicator {
  //   background-color: white !important;
  color: white;
}

.style-home-resources-settings-icon {
  width: 25px !important;
  height: 25px !important;
  float: right !important;
  margin-right: -20px !important;
  margin-left: -25px !important;
}

.css-1kz9i0u-option {
  display: inline-flex !important;
}

.css-1s3fu3f-option {
  display: inline-flex !important;
}

.css-yvjt3i-option {
  display: inline-flex !important;
}

// .resources-setting-icon-style {
//   margin-top: -8px !important;
// }
.css-26l3qy-menu {
  z-index: 1000 !important;
}

.css-1b9qvxs-option,
.css-qhzwzy-option {
  display: inline-flex !important;
}

.css-u4vj3z-option,
.css-wit834-option {
  display: inline-flex !important;
}

.MuiAutocomplete-popupIndicatorOpen {
  background-color: white !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-layout .css-117w1su-MuiStepIcon-text {
  fill: white !important;
  color: gray;
}

.dark-layout .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: gray;
}

.dark-layout .css-1i6irnb-singleValue {
  color: white !important;
}

.dark-layout .ant-select-dropdown-placement-topLeft {
  background-color: rgb(40, 48, 70);
}

.dark-layout .ant-select-dropdown-placement-bottomLeft {
  background-color: rgb(40, 48, 70);
}

.dark-layout .ant-select-item-option-content {
  color: white;
}

.dark-layout .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #1976d2;
}

.dark-layout .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #1976d2;
}

.dark-layout .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #1976d2;
}

.dark-layout .performance-ratings-title-style {
  font-size: 12px !important;
  white-space: nowrap !important;
}

.performance-ratings-title-style {
  font-size: 12px !important;
  white-space: nowrap !important;
}

.add-icon-resource {
  text-transform: capitalize;
  font-size: 12px;
  color: #363430;
}

.add-icon-project {
  text-transform: capitalize;
  font-size: 12px;
  margin-left: 4px;
}

.dark-layout .css-4b67si-MuiSvgIcon-root {
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.css-4b67si-MuiSvgIcon-root {
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.dark-layout .avatar-style-icon {
  // margin-left: 9px !important;
  width: 50px !important;
  height: 50px !important;
  margin-right: 10px !important;
}

.avatar-style-icon {
  // margin-left: 9px !important;
  width: 50px !important;
  height: 50px !important;
  margin-right: 10px !important;
}

.avatar-style-icon-img {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 50px !important;
  margin-right: 14px !important;
}

.avatar-with-icon-status {
  position: absolute;
  bottom: -15px;
  left: 38px;
  transform: translateX(-50%);

  &.claims_icon {
    bottom: -14px;
    left: 41px;
  }
}

.avatar-status-online {
  position: absolute;
  bottom: 0;
  left: 90%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.dark-layout .avatar-style-icon {
  width: 50px !important;
  height: 50px !important;
  margin-right: 14px !important;
}

.dark-layout .css-1x5jdmq {
  background-color: rgb(5, 26, 56) !important;
}

.dark-layout .engagement-delete-icon {
  width: 16px !important;
  margin-left: 8px !important;
  margin-right: 3px !important;
}

.engagement-delete-icon {
  width: 16px !important;
  margin-left: 8px !important;
  margin-right: 3px !important;
}

// .style-settings-icon {
//   width: 25px !important;
//   height: 25px !important;
// }
.css-1vhtejn-option {
  display: inline-flex !important;
}

.css-1fw6bil-option {
  display: inline-flex !important;
}

.checkbox-select-style {
  margin-right: 5px !important;
}

.css-gqc4lk-option {
  display: inline-flex !important;
}

.dark-layout .calender_component .ant-select-arrow {
  color: black !important;
}

.dark-layout .bin-icon {
  color: white;
}

.dark-layout .css-h4y409-MuiList-root {
  margin-top: 3.5px !important;
}

.css-h4y409-MuiList-root {
  margin-top: 3.5px !important;
}

.dark-layout .ant-pagination-options {
  margin-right: 12px !important;
}

.ant-pagination-options {
  margin-right: 12px !important;
}

.read-more-less--more {
  color: #0460a9 !important;
}

.read-more-less--less {
  color: #0460a9 !important;
}

.dark-layout .ant-pagination-next {
  margin-right: 8px !important;
}

.ant-pagination-next {
  margin-right: 12px !important;
}

.dark-layout .row-field-wrap {
  margin-top: 5px !important;
}

.row-field-wrap {
  margin-top: 5px !important;
}

.dark-layout .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: white !important;
}

.dark-layout .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: white !important;
}

.dark-layout .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: white !important;
}

.dark-layout .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: white !important;
}

.dark-layout .technologySelect {
  color: white !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #98a0ae !important;
}

.dark-layout .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid white !important;
}

.dark-layout .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background: #051a38 !important;
}

.portfolio-attachments {
  margin-left: 41px;
  font-size: 14px;
  color: grey;
}

.dark-layout .css-igs3ac {
  border: 1px solid white !important;
}

.css-igs3ac {
  border: 1px solid #98a0ae !important;
}

.ant-pagination-next {
  margin-right: 8px !important;
}

.ant-pagination-options {
  margin-left: 0 !important;
}

.dark-layout .ant-pagination-options {
  margin-left: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  transition: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  transition: none !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: none !important;
}

.delete-icon-color {
  fill: #ea5455 !important;
}

.modal-content .modal-header {
  background-color: #f8f8f8;
  border-bottom: 0 solid #ebe9f1;
  align-items: center;
}

.modal-content .webhook-del-modal.modal-header {
  align-items: flex-start !important;
}

.modal .modal-header .close {
  transform: translate(18px, -10px);
  margin: -0.4rem -0.7rem -0.4rem auto;
}

.modal .modal-header .close:hover {
  transform: translate(7px, -2px);
}

.MuiDialog-root {
  z-index: 900 !important;
}

.main-menu {
  z-index: 1000;
}

.dark-layout .parent-bar-chart-performance:hover {
  cursor: pointer !important;
}

.parent-bar-chart-performance:hover {
  cursor: pointer !important;
}

.dark-layout .parent-bar-chart-performance .chartjs-render-monitor {
  display: flex !important;
  float: left !important;
  height: 52px !important;
  width: 120px !important;
}

.parent-bar-chart-performance .chartjs-render-monitor {
  display: flex !important;
  float: left !important;
  height: 52px !important;
  width: 120px !important;
}

.dark-layout .style-header-performance-detail {
  padding-left: 20px !important;
  margin-top: 27px !important;
}

.style-header-performance-detail {
  padding-left: 20px !important;
  margin-top: 27px !important;
}

.dark-layout .style-header-last-performance-detail {
  margin-top: 12px !important;
  padding-left: 20px !important;
}

.style-header-last-performance-detail {
  margin-top: 12px !important;
  padding-left: 20px !important;
}

.loader-view-detail {
  margin-left: 52px !important;
  margin-top: 16px !important;
}

.dark-layout .loader-view-detail {
  margin-left: 52px !important;
  margin-top: 16px !important;
}

.dark-layout .noData-performance {
  white-space: nowrap !important;
  background-color: white;
  width: 120px !important;
  height: 52px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noData-performance {
  white-space: nowrap !important;
  background-color: white;
  width: 120px !important;
  height: 52px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-layout .ant-upload-list-item-info {
  transition: none !important;
}

.resourcesDrop {
  // z-index: 999;

  .MuiList-root {
    .MuiMenuItem-root {
      &:hover {
        .dark-link {
          color: white;
        }

        .light-link {
          color: black;
        }
      }

      .light-link {
        color: #666666;
        transition: 0s;
      }

      .dark-link {
        color: white;
        transition: 0s;
      }
    }
  }
}

.dark-layout .content-margin-timeline {
  margin-left: 0px !important;
}

.content-margin-timeline {
  margin-left: 0px !important;
  // display: inline-block;
  word-break: break-word;

  .invoice-list-dataTable {
    .css-15e2xn8-container {
      margin-bottom: 0px !important;
    }
  }
}

.myProjectIcon {
  .css-15e2xn8-container {
    margin-bottom: 0px !important;
  }
}

.iconBtn {
  width: 35px;
  height: 35px;
  border-color: #2176ff;
  background-color: #2176ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.358rem;
  padding: 0;
  cursor: pointer;
}

.iconBtn:hover:not([disabled]) {
  box-shadow: 0 8px 25px -8px #2176ff;
  cursor: pointer;
}

.iconBtn svg {
  width: 17px;
  height: 17px;
}

.invoice-list-dataTable {
  .css-15e2xn8-container {
    margin-bottom: 0 !important;
    z-index: 10002;
  }
}

.deleteBtn {
  background-color: #D11A2A !important;
  border: none;
  &:focus {
  background-color: #D11A2A !important;
  }
}

.upSellTable {
  .css-1wft2k9-container {
    margin-bottom: 0 !important;
  }
}

.createProjectSection {
  .css-1qm1lh {
    margin-bottom: 0 !important;
  }
}

.modal-body {
  .invoice-list-dataTable {
    .projectHeading {
      display: none !important;
    }
  }

  @media (max-width: 360px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.projectHeading {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.resourcesDrop[data-popper-reference-hidden] {
  display: none;
}

.w-20 {
  width: 20px;
}

.marginTop {
  margin-top: 0 !important;
}

.hover-item:hover {
  color: black !important;
}

.theming {
  width: 100%;

  &:hover {
    background-color: #c6e2ff !important;
  }
}
.review-icon{
  fill: #b4b7bd !important;
}
.dark-layout {
  .theming {
    &:hover {
      background-color: #3498db !important;
    }

    &:hover .hover-item {
      color: white !important;
      .review-icon{
        fill: white !important;
      }
      
    }
    
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  &:hover {
    background-color: #c6e2ff !important;
  }
}

.dark-layout {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    &:hover {
      background-color: #3498db !important;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #c6e2ff !important;
}

.dark-layout .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3498db !important;
}

.MuiAutocomplete-option {
  &:hover {
    background-color: #c6e2ff !important;
  }
}

.dark-layout {
  .css-gdh49b-MuiAutocomplete-listbox {
    padding: 0 !important;
  }

  .MuiAutocomplete-option {
    background-color: #132847 !important;
    color: white;

    li {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }

    &:hover {
      background-color: #3498db !important;
    }
  }
}

.dark-layout {
  .ant-notification-notice-closable .ant-notification-notice-message {
    color: white !important;
  }

  .ant-notification-notice {
    z-index: 1065 !important;
    background-color: black !important;
    color: white !important;
  }
}

.ant-notification {
  z-index: 1065 !important;
}

.scroller {
  width: 120px;
  overflow: auto;
  overflow-y: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 1.3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(238, 237, 237);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 0.2px solid rgb(238, 237, 237);
    /* creates padding around scroll thumb */
  }
}

.notification-table-row.bg-light-gray {
  &:hover {
    color: black !important;
  }

  background: #e6f7ff;
  color: black;
}

.dark-layout .notification-table-row.bg-light-gray {
  background: #283046 !important;
  color: #b4b7bd !important;

  &:hover {
    color: white !important;
  }
}

.notification-table-row {
  .ant-table-cell {
    white-space: pre-line;
  }
}

.notCollpaseWidth1 {
  width: 25%;
}

.collpaseWidth1 {
  width: 25%;
}

.notCollpaseWidth2 {
  width: 25%;
}

.collpaseWidth2 {
  width: 25%;
}

.notCollpaseWidth3 {
  width: 25%;
}

.collpaseWidth3 {
  width: 25%;
}

.notCollpaseWidth4 {
  width: 25%;
}

.collpaseWidth4 {
  width: 25%;
}

.dark-layout .text-muted {
  color: #1756b5 !important;
}

.text-muted {
  color: #1756b5 !important;
}

.dark-layout {
  .colorCombination {
    color: white;
  }
}

.colorCombination {
  color: rgba(0, 0, 0, 0.85);
}

.whiteBgTable {
  .colorCombination {
    color: rgba(0, 0, 0, 0.85);
  }
}

.dark-layout .whiteBgTable {
  .colorCombination {
    color: white;
  }
}

.dark-layout {

  .green,
  .green1,
  .yellow,
  .purple {
    background-color: #0c1c33;
    margin-right: 5px;
  }
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background-color: transparent !important;
}

.pd-y {
  padding: 5px 0 20px 0;
}

.pTop {
  padding-top: 25px;
}

.ant-table-thead .csm-severity {
  padding-left: 45px;
}

.ant-table-thead .resource-avail {
  padding-left: 17px;
}

.ant-table-thead .permissions-action {
  padding-left: 30px;
}

.ant-table-thead .roles-permission {
  padding-left: 30px;
}

.ant-table-thead .skills-tab {
  padding-left: 23px;
}

.ant-table-thead .proj-action {
  padding-left: 30px;
}

.ant-table-thead .division-action {
  padding-left: 28px;
}

.ant-table-thead .project-status {
  padding-left: 41px;
}

.MuiPaper-root.MuiPaper-elevation {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);

  @media (max-width: 768px) {
    height: 167px;
    overflow: auto;
  }
}

.dark-layout .MuiPaper-root.MuiPaper-elevation {
  background: #0c1d33 !important;
  color: #fff !important;
}

.dark-layout .MuiPaper-root.MuiPaper-elevation svg,
.dark-layout .MuiPaper-root.MuiPaper-elevation .hover-item {
  color: #fff !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  background-color: transparent !important;
}

.dark-layout .basic-select-dark div>div>div>div {
  color: #fff;
}

#attendance .dropdown-menu {
  padding: 0;
  min-width: 8.7rem;
  background-color: none;
  overflow: hidden !important;
}

#attendance .dropdown-menu .dropdown-item {
  color: #000;
}

#attendance .dropdown-toggle::after {
  content: '';
  background-image: url('../images/icons/arrowDown.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  position: relative;
  top: 2px;
}

.capitalize {
  text-transform: capitalize;
}

.rc-virtual-list {
  background-color: #fff;
}

.dark-layout .rc-virtual-list {
  background-color: #283046;
}

.ant-select-dropdown {
  padding: 0 !important;
}

.ant-select-item-option-active {
  background-color: transparent !important;
}

#resource_table {
  .ant-table-body {
    .ant-table-cell {
      color: #000;
    }
  }
}

.tableHeight .ant-table-pagination.ant-pagination,
#tableHeight .ant-table-pagination.ant-pagination,
#resource_table .ant-table-pagination.ant-pagination {
  padding: 16px 0 !important;
  margin: 0 !important;
}

.dark-layout .tableHeight .ant-table-pagination.ant-pagination,
.dark-layout #tableHeight .ant-table-pagination.ant-pagination,
.dark-layout #resource_table .ant-table-pagination.ant-pagination {
  padding: 16px 0 !important;
  margin: 0 !important;
}

#csmTableHeight .ant-table-pagination.ant-pagination {
  padding: 10px 0 0 0 !important;
  margin: 0 !important;
}

.dark-layout #csmTableHeight .ant-table-pagination.ant-pagination {
  padding: 10px 0 !important;
  margin: 0 !important;
}

#department>div>div>.css-qevtrk-placeholder {
  overflow: hidden;
  height: auto;
  line-height: 14px;
}

// #sub_department > div > div > div {
//   overflow: hidden;
//   height: 30px;
//   line-height: 15px;
// }
.edit_width {
  width: 17px;
}

.favIconImg {
  width: 60px;
  height: 60px;
}

.iconSize {
  height: 16px;
  width: 16px;
}

// this class is use for i.e home page on going projects column circle
.bg-circle {
  background: #e0fff7;
  border-radius: 50%;
  padding: 22px;
  height: fit-content;
  margin-top: 5px;
}

.bg-blue {
  background: rgb(238, 246, 250);
}

.bg-purple {
  background: #f9f6fc;
}

.bg-yellow {
  background: #fbf7e8;
}

// this class is use for i.e half, secondhalf on leaves
.small_text {
  font-size: 8px;
}

// this class is use for i.e Evaluation => [pending]
.md_text {
  font-size: 16px;
}

// for tables sticky top
.stickyTop {
  position: sticky;
  top: 0;
  z-index: 999;
}

// Linked texts
.blue_text {
  cursor: pointer;
  color: #1890ff;
}

// red light
.text_danger_light {
  color: #ff6b6b;
}

// green texts
.text-green {
  color: green;
}

// light red background
.bg-red {
  background-color: #ea5455 !important;
}

// Edit icon
.editIcon {
  width: 17px;
  margin-right: 3px;
  margin-top: 0.5px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

// dark text
.text_dark {
  color: #000;
}

.bg-dark-red {
  background: #ff0000 !important;
}

.dark-layout {
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
}

.ant-table-thead .project_manager_pd {
  padding-left: 16px;
}

.sm-size {
  font-size: 11px;
}

.btn-tab {
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
}

.btn-tab-active {
  border-bottom: 2px solid #2176ff;
  font-weight: 500;
  color: #2176ff;
  padding: 0 5px;
}

.dark-layout {
  .btn-tab-active {
    background: transparent;
    color: #2176ff !important;
  }

  .btn-tab {
    color: #fff;
  }
}

.uploadBtn {
  button {
    display: flex;
    align-items: center;
    padding-left: 11px;

    svg {
      height: 0.9em;
      width: 0.9em;
      margin-top: 1px;
    }
  }
}

#commonModal {
  .tableHeight {
    .ant-table-content {
      overflow: scroll !important;
    }
  }
}

.dark-layout {
  .upload-list-inline {
    .ant-upload-list-item-name {
      color: #fff;
    }

    .ant-upload-list-item-card-actions .anticon {
      color: #fff;
    }
  }

  input:disabled {
    border-color: #666666;
    background-color: #1c2f4b;
    color: #959595;

    &:hover {
      border-color: #666666 !important;
    }
  }

  .basic-select-dark {
    >div {
      background-color: transparent;
    }

    // >div {
    //   >div {
    //     >div {
    //       color: #fff;
    //     }
    //   }
    // }
  }
}

input:disabled {
  &:hover {
    border-color: #666666 !important;
  }
}

/* Add the following style to control text overflow */
.text-container {
  max-height: 66px;
  /* Show 2 lines */
  overflow: hidden;
  position: relative;
}

.text-container.show-more {
  color: unset !important;
}

/* Style for the "Show More" link */
.show-more-link {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  color: #2176ff;
  cursor: pointer;
  display: block;
  text-align: left;
  margin-top: 5px;
}

.division_list_style {
  min-height: 55px;
  width: 120px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.projectType_list_style {
  min-height: 55px;
  width: 120px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  background-color: rgb(150, 206, 180);
}

.service_mode {
  min-height: 55px;
  width: 120px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;
  color: white;
  font-weight: 600;
  background-color: #7366f0;
}

.trial {
  min-height: 55px;
  width: 120px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  background-color: #fbe7e5;
}

.onboading-action-btn {
  button {
    padding: 1.5px !important;

    &:first-child {
      margin-left: 7px;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.floatleft {
  float: left;
}

.input-header {
  width: 242px !important;

  &::placeholder {
    font-size: 12px !important;
    // color: rgba(110, 107, 123) !important;
    font-weight: 500 !important;
    // opacity: 0.5 !important;
  }

}

.dark-layout .input-header {
  &::placeholder {
    color: #6e6b7b !important;
  }
}

.dark-layout .ant-picker-input>input {
  color: #fff !important;
}

.ant-picker-input>input {
  &::placeholder {
    color: #6e6b7b !important;
  }
}

.dark-layout .ant-picker-input>input {
  &::placeholder {
    color: #6e6b7b !important;
  }
}

.dark-layout .input-header-placeholder {
  &::placeholder {
    color: #6e6b7b !important;
  }
}

.card-bg-home-tab {
  background-color: #dfe9f1 !important;
  margin-right: 5px !important;
}

.dark-layout .card-bg-home-tab {
  background-color: #0c1c33 !important;
  margin-right: 5px !important;
}

.dark-layout {
  .ck.ck-button:not(.ck-disabled):hover {
    background-color: #0c1c33 !important;
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active:focus {
    box-shadow: none !important;
  }

  .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list {
    background-color: #0c1c33 !important;

    button {
      box-shadow: none !important;
      border: none !important;
    }
  }

  .ck.ck-button:active {
    border: none !important;
  }

  .ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-button:not(.ck-disabled):active {
    background-color: #0c1c33 !important;
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    background-color: #0c1c33;

    button {
      background-color: #0c1c33;
      color: #fff;
      box-shadow: none !important;
    }

    svg {
      color: #fff;
      opacity: unset !important;
    }
  }

  .ck.ck-list__item .ck-button.ck-on {
    background: #2176ff !important;
  }

  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
  .ck.ck-editor__main>.ck-editor__editable {
    background-color: #132847;
    color: #fff;
  }

  .ck.ck-editor__main>.ck-editor__editable {
    border-color: #ccced1 !important;
  }
}

.dark-layout {
  .history-button {
    color: #b4b7bd;
  }
}

// .marraige-claim {
//   .ant-upload-list {
//     margin-top: 6px;

//     .ant-upload-list-picture-container {
//       margin: 8px 0 !important;
//     }
//   }
// }

.marraige-claim,
.child-claim {
  .ant-upload-list {
    margin-top: 6px;
    max-height: 258px;
    overflow: auto;

    .ant-upload-list-picture-container {
      margin: 8px 0 !important;
      height: 42px !important;

      .ant-upload-list-item {
        margin-top: unset !important;
        height: 42px !important;

        @media (max-width: 575px) {
          width: 212px;
        }
      }
    }
  }
}

.other-claim {
  .ant-upload-list {
    margin-top: 6px;
    max-height: 144px;
    overflow: auto;

    .ant-upload-list-picture-container {
      display: inline-block !important;
      height: 42px !important;
      // transition: none !important;

      .ant-upload-list-item {
        margin-top: unset !important;
        height: 42px !important;
      }
    }
  }
}

.child-claim-allowance {
  .ant-upload {
    width: 100%;
  }

  .ant-upload-list-picture .ant-upload-list-item {
    max-width: 170px;
    width: 100% !important;

    @media (max-width: 767px) {
      max-width: 325px;
    }

    @media (max-width: 575px) {
      max-width: 100%;
    }

    margin-top: 2px !important;
    height: 39px !important;

    a {
      width: 22px !important;

      img {
        width: 22px !important;
      }
    }
  }
}

.medical-claim {
  .ant-upload {
    width: 100%;
  }

  .ant-upload-list {
    .ant-upload-list-picture-container {
      transition: none !important;

      .ant-upload-list-item {
        margin-top: unset !important;
        transition: none !important;
        width: 100%;
      }
    }
  }
}

.document-fields-wrapper {
  @media (min-width: 576px) {
    height: 38px;
  }

  .document-fields {
    padding-bottom: 15px;
  }
}

.date-picker {
  .ant-picker-input>input:placeholder-shown {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #6e6b7b !important;
  }
}

.child-claim-upload {
  max-width: 170px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 325px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }

  height: 38px !important;
  border-radius: 0.357rem !important;
  margin-top: 2.5px;
}

input[type='date' i] {
  padding-left: 10px !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  padding-left: 2px !important;
  margin-top: 2px !important;

  .reject-btn {
    padding: 0px 10px 10px !important;
    width: 42px;
    height: 42px;
  }
}

.overtime-date-type {
  .ant-picker-suffix {
    margin-top: 9px;
  }

  .ant-picker-clear>* {
    vertical-align: unset !important;
  }

  .ant-picker-clear {
    margin-top: 3px;
  }
}

.ant-picker-clear>span {
  margin-top: 10px !important;
}

.ant-picker-input {
  .anticon {
    &.anticon-close-circle {
      margin-top: 0 !important;
    }
  }
}

.dark-layout {
  .resourceDatePicker {
    .ant-picker-clear {
      background-color: #132847;
      color: #fff;
    }
  }
}

.placeholder-color {
  .css-qevtrk-placeholder {
    color: #6e6b7b !important;

    .designation-dropdown {
      .css-qevtrk-placeholder {
        line-height: 14px;
      }
    }
  }
}

.dark-layout {
  .assigned-project-collapse {
    .ant-collapse-content {
      background-color: #0c1c33 !important;
    }
  }
}

a.ant-upload-list-item-file {
  cursor: default !important;
}

.document-upload-new {
  .ant-upload {
    width: 100%;
  }

  .ant-upload-list {
    .ant-upload-list-picture-container {
      transition: none !important;

      .ant-upload-list-item {
        margin-top: unset !important;
        transition: none !important;
        height: 41.5px;
        width: 100%;
        border-radius: 5px;

        .ant-upload-list-item-thumbnail {
          width: 30px;
          height: 27px;
          line-height: unset;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// .project-document-icons>span {
//   margin-left: 153px !important;
// }

.fileUpload {
  .ant-upload-list-item-thumbnail.ant-upload-list-item-file {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.offboarding {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgb(255, 204, 128) !important;
    border: none !important;
    border-color: rgb(255, 204, 128) !important;
    border-radius: 3px !important;
    height: 34px !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: none !important;
  }

  .ant-select-selection-item {
    line-height: 35px !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important;

    &:hover {
      background-color: unset !important;
    }
  }
}

.darkModeOffboarding {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #ffcc80d9 !important;
  }

  .ant-select-selection-item {
    color: #fff !important;
  }
}

.custom-dropdown {
  .ant-select-item {
    &:nth-child(1) {
      background-color: rgb(150, 206, 180) !important;

      &:hover {
        background-color: rgb(131, 205, 171) !important;
      }
    }

    &:nth-child(2) {
      background-color: rgb(255, 178, 178) !important;

      &:hover {
        background-color: rgb(249, 163, 163) !important;
      }
    }
  }
}

.DarkcustomDropdown {
  .ant-select-item {
    &:nth-child(1) {
      background-color: #96ceb4de !important;
      color: #fff;
    }

    &:nth-child(2) {
      color: #fff;
    }
  }
}

.text-dargGrey {
  color: #5e5d5d !important;
}

.onboardingPreSale {
  margin-top: 20px;
}

.mothlyAuditChip {
  span {
    overflow: unset;
    text-overflow: unset;
    white-space: normal;
  }
}

.engagementStatTable {

  tr.ant-table-expanded-row:hover>td,
  tr.ant-table-expanded-row>td {
    background: transparent !important;
  }

  .ant-table-expanded-row-fixed {
    background: #f2f0f0;
  }

  .resourcesSummarySubTableLight {

    // border: 1px solid #aaaaaa;
    .ant-table-container {
      .ant-table-header {
        .ant-table-thead {
          th {
            background-color: #f2f0f0 !important;
          }
        }
      }
    }
  }

  .resourcesSummarySubTableDark {

    // border: 1px solid #b7b5b5;
    .ant-table-container {
      .ant-table-header {
        .ant-table-thead {
          th {
            background-color: #52596a !important;
            color: #fff;
          }
        }
      }
    }
  }
}

.engagementStatTableDark {

  tr.ant-table-expanded-row:hover>td,
  tr.ant-table-expanded-row>td {
    background: transparent !important;
  }

  .ant-table-expanded-row-fixed {
    background: #343d55;
  }

  .ant-table-row-expand-icon-cell {
    .dark-layout .ant-table-row-expand-icon {
      background-color: #0c66e4 !important;
      color: #fff !important;
    }
  }

}

.fw_600 {
  font-weight: 600;
}

.font_16 {
  font-size: 16px;
}

.lh_20 {
  line-height: 20px;
}

.employee_designation {
  color: #0c66e4;
  font-size: 14px;
  font-weight: 500;
}

.font_10 {
  font-size: 10px;
}

.showMoreBtn {
  line-height: 18px;
  font-size: 12px;
  color: #2176ff;
  font-weight: 600 !important;
  text-transform: unset !important;
}

.btn-purple {
  background-color: #7366f0 !important;
  color: #fff;
  font-size: 12px;
  padding: 0.6rem;
  height: 37.11px !important;
  margin-right: 10px;
}

.projectNature_selector {
  .project_nature_modal {
    .value_container_main__value-container {
      height: unset !important;
      max-height: 65px;
      overflow: auto;
    }
  }
}

.dark-layout {
  .projectNature_selector {
    .project_nature_modal {
      .value_container_main__value-container {
        .value_container_main__multi-value__label {
          color: #fff;
        }
      }
    }
  }
}

.skills-filters-wrap {
  gap: 10px;
  flex-wrap: wrap;
}

.gap_5 {
  gap: 5px;
}

.gap_10 {
  gap: 10px;
}

.monthly_claim_filter {
  .claims-container {
    .claims__control {
      width: unset !important;
    }
  }
}

@media (max-width: 1320px) {
  .top-cards>div {
    width: calc(50% - 3px);
    overflow: hidden;
  }

  .top-cards>div>div {
    margin: 0 !important;
  }

  .top-cards {
    gap: 5px;
  }
}

@media (max-width: 767px) {
  .top-cards .bg-circle {
    padding: 14px;
  }

  .top-cards>div>div {
    padding: 1em !important;
  }
}

@media (max-width: 500px) {
  .top-cards .bg-circle {
    padding: 2px;
  }

  .top-cards>div>div {
    font-size: 11px;

    .fontSize34 {
      font-size: 26px;
    }
  }
}

.pending-pm,
.add-claim,
.reviews-claims {
  &.add-claim {
    @media (max-width: 575px) {
      max-width: unset !important;
    }
  }
}

.radio-label {
  font-size: 13px;
  color: #000;
}

.input-radio {
  width: 10px;
}

.noOfResources_input {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;

  &:focus {
    color: #000 !important;
  }

  &::placeholder {
    color: #7f8a9b !important;
    font-weight: 500 !important;
    font-size: 11px !important;
  }
}

.noOfResources_input_dark {
  color: #fff !important;

  &:focus {
    color: #fff !important;
  }

  &::placeholder {
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 11px !important;
  }
}

.modal-content {
  @media (max-width: 1099px) {
    max-width: 95%;
    margin: 0 auto;
  }
}

.claims-date-filter {
  .ant-picker-suffix {
    margin-top: 8px;
  }
}

@media (max-width: 767px) {
  .childFeeClaim_row_input {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.add_request_modal {
  .modal-content {
    @media (max-width: 991px) {
      width: 700px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      width: 530px;
    }
  }
}

.row-gap-custom {
  row-gap: 7px;
}

.ant-table-header {
  table {
    thead {
      tr {
        th {
          &:first-child {
            @media (max-width: 767px) {
              position: static !important;
            }
          }
        }
      }
    }
  }
}

.ant-table-tbody {
  .ant-table-row-level-0 {
    .ant-table-cell {
      @media (max-width: 767px) {
        position: static !important;
        left: unset !important;
      }

      span {
        &::after {
          @media (max-width: 767px) {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.ant-table-tbody {
  .ant-table-row-level-0 {
    .ant-table-cell {
      @media (max-width: 767px) {
        position: static !important;
        left: unset !important;
      }

      span {
        &::after {
          @media (max-width: 767px) {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .top-cards>div {
    width: calc(50% - 3px);
    overflow: hidden;
  }

  .top-cards>div>div {
    margin: 0 !important;
  }

  .top-cards {
    gap: 5px;
  }
}

@media (max-width: 767px) {
  .top-cards .bg-circle {
    padding: 14px;
  }

  .top-cards>div>div {
    padding: 1em !important;
  }
}

@media (max-width: 576px) {
  .AddDocModal.modal-body .col-5 {
    padding: 2px;
  }

  .AddDocModal .row {
    margin: 0;
  }
}

@media (max-width: 500px) {
  .top-cards .bg-circle {
    padding: 2px;
  }

  .top-cards>div>div {
    font-size: 11px;

    .fontSize34 {
      font-size: 26px;
    }
  }
}

@media (max-width: 767px) {

  .ant-table-cell-fix-left-last,
  .ant-table-column-sorters {
    &:after {
      display: none !important;
      box-shadow: none;
    }
  }
}

@media (max-width: 575px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-range-wrapper .ant-picker-date-range-wrapper {
    justify-content: center;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}

@media (max-width: 940px) {
  .responsive-width-100 {
    width: 100% !important;

    .input-header {
      width: 100% !important;
    }

    .date-picker-range {
      width: 100% !important;
    }
  }
}

.responsive-select-container {
  .responsive-select__control {
    @media (max-width: 599px) {
      width: unset !important;
    }
  }
}

.modal-input-resp {
  @media (max-width: 599px) {
    width: 100% !important;
  }
}

.add_resource_modal {
  .modal-content {
    @media (max-width: 599px) {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}
.add_resource_modal_projects {
  .modal-content {
    max-width: 580px !important;
    margin: 0 auto !important;
  }
}

.ant-table-cell-fix-left-last:after {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.resource-detail-tab {
  button {
    &:first-child {
      @media (max-width: 991px) {
        min-width: 230px !important;
      }
    }

    &:nth-child(2) {
      @media (max-width: 991px) {
        min-width: 151px !important;
      }
    }

    &:nth-child(3) {
      @media (max-width: 991px) {
        min-width: 175px !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 293px !important;
    left: unset !important;
  }
}

.table_h {
  flex-wrap: nowrap !important;

  .table_c {
    &:first-child {
      width: 400px !important;
    }
  }
}

.table_h2 {
  flex-wrap: nowrap !important;

  .table_c2 {
    width: 200px !important;

    @media (max-width: 767px) {
      &:last-child {
        width: 120px !important;
      }
    }

    padding-top: 7px;
  }
}

.timeline_date {
  .MuiInputBase-input {
    width: 200px !important;

    @media (max-width: 599px) {
      width: 200px !important;
    }
  }
}

.timeline_select {
  margin-top: 1px;

  .timeline__control {
    @media (max-width: 599px) {
      width: 221px !important;
    }
  }
}

.timeline_mobile {
  .MuiTimelineItem-root {
    @media (max-width: 767px) {
      flex-direction: column !important;
    }
  }

  .MuiTimelineSeparator-root {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.resources_tab {
  .ant-table-column-sorters:after {
    display: none;
  }
}

.resourcesDrop {
  .MuiPaper-root {
    .MuiButtonBase-root {
      min-height: 20px !important;
    }
  }
}

.add_request_upload {
  .ant-upload-list-picture .ant-upload-list-item {
    width: unset !important;

    .leave_apply_m {
      .MuiAvatarGroup-root {
        flex-wrap: wrap;
      }
    }
  }
}

.m_l_table {
  table {
    colgroup {
      col {
        &:first-child {
          @media (max-width: 410px) {
            width: 212px !important;
          }
        }
      }
    }
  }

  .ant-table-header {
    background-color: #f3f2f7 !important;
  }

  .ant-table-body {
    background-color: #fafafa !important;
  }
}

.dark-layout {
  .m_l_table {
    .ant-table-header {
      background-color: #132847 !important;
    }

    .ant-table-body {
      background-color: #132847 !important;
    }
  }
}

.engagement_lead-container {
  .engagement_lead__control {
    width: 242px !important;
    height: 40px !important;
  }
}

.eval-select-container {
  .eval-select__control {
    width: 242px;
  }
}

.offline-request-select {
  .offline-manager-container {
    .offline-manager__control {
      .offline-manager__value-container {
        .offline-manager__single-value {
          color: #6e6b7b !important;
        }
      }
    }
  }
}

.dark-layout {
  .offline-request-select {
      .offline-manager-container {
        .offline-manager__control {
          .offline-manager__value-container {
            .offline-manager__single-value {
              color: #fff !important;
            }
          }
        }
      }
    }
}

.offline-request-date {
  .datePicker {
    input {
      color: #6e6b7b !important;
    }
  }
}

.slack-avatar-offline {
  width: unset !important;
}

.support-pie-chart {
  svg {
    background-color: unset !important;
  }
}

.dark-layout .ant-dropdown-menu {
  background-color: #051A38;

  .ant-dropdown-menu-item {
    color: #fff;

    &:hover {
      background-color: #3498db;
    }
  }
}

.dark-layout {
  .ant-picker-clear {
    background-color: #000;
    color: grey;
    &:hover {
      color: lightgray;
    }
  }
}

// input {
//   color-scheme: dark;
// }

.ant-datePicker-wrapper {
  border-radius: 0.357rem !important;
  border: 1px solid #98a0ae;
}

.dark-layout {
  .ant-datePicker-wrapper {
    border-color: #fff;
  }
  .notifcationAdd{
    path{
      fill: #b4b7bd;
    }
    &:hover{
      path{
        fill: #ffff;
      }
    }
  }
  .FeedBackOutline{
    path{
      fill: grey;
      cursor: pointer;
    }
    &:hover{path {
      fill: #fff;
    }}
  }
}

.show_more_less{
	width: 19px;
	height: 19px;
	path{
		fill: rgb(33, 118, 255);
	}
}
.ant-pagination-options{
  @media (max-width: 576px) {
    display: inline-block !important;
  }
}
.ant-steps-item-title{
  line-height: 18px !important;
}
.loading_box{
  width: 130px;
  height: 120px;
  background-color: #e4e4e4;
}
.update_resume_btns{
&:focus{
  background-color: #fff !important;
}
}
.update_resume_btns_cancel{
  background-color: #fff !important;
  color: #5e5873 !important;
  border: 1px solid #5e5873 !important;
  background: #fff !important;
  &:focus{
    background-color: #fff !important;
  }
}
.remove_btns{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  border: none !important;
  color: #ea5455 !important;
  font-size: 14px;
  padding: 10px !important;
  &:focus{
    background-color: #fff !important;
  }
}
.add_circle_icon{
  width: 20px;
  @media (min-width: 768px){
    margin-left: 20px;
  }
  &.education_icon{
    margin-left: 20px;
  }
  height: 20px;
  cursor: pointer;
  path{
    fill: #1976D2;
  }
}
.remove_circle_icon{
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
  path{
    fill: #EA5455;
  }
}
.update_resume_steps{
  @media (max-width: 575px) {
    padding-left: 20px !important;
}
.ant-steps-item-title{
  @media (max-width: 575px) {
    margin-top: 7px;
}
}
}
.select_tools{
  border-radius: 0.317rem !important;
  .ant-select-selector{
    border: 1px solid #98a0ae !important;
    height: 2.714rem;
    border-radius: 0.317rem !important;
  }
}
.resume_btn{
  font-weight: 500 !important;
  font-size: 0.8125rem;
  color: white;
  box-shadow: none !important;
  border-radius: 4px;
  height: 32px;
  padding: 0;
  &.create_resume_btn{
    background-color: rgb(115, 103, 240) !important;
  }
  &.upload_resume_btn{
    background-color: rgb(33, 118, 255) !important;
  }
  &.view_resume_btn{
    background-color: gray !important;
  }
}
.yes_no_modal{
  .modal-content{
    max-width: 500px !important;
    margin: 0 auto !important;
  }
}
.delete_skill_btn{
  background-color: transparent !important;
  border: 1px solid red !important;
  &:hover{
    background-color: transparent !important;
    border: 1px solid red !important;
  }
  svg{
    path{
      fill: red;
    }
  }
}
.feedBack_icon{
  width: 20px;
  height: 20px;
}
.dark-layout{
  .feedBack_icon{
    path{
      fill: #ffff;
    }
  }
  .InfoOutlinedIcon{
    path{
      fill: #ffff;
    }
  }
}
.Plus_addskill{
  background-color: transparent !important;
  border: 1px solid rgb(33, 118, 255) !important;
  svg{
    path{
      fill: rgb(33, 118, 255);
    }
  }
  &:hover{
    background-color: rgb(33, 118, 255)!important;
    border: transparent !important;
    svg{
      path{
        fill: #fff;
      }
    }
  }
}
.Check_skill{
  background-color: rgb(40, 199, 111) !important;
  border: transparent !important;
  svg{
    path{
      fill: #fff;
    }
  }
  &:hover{
    background-color: rgb(40, 199, 111) !important;
    border: transparent !important;
    svg{
      path{
        fill: #fff;
      }
    }
  }
}

.close_skill_icon{
  background-color: transparent !important;
  border: 1px solid red !important;
  svg{
    path{
      fill: red;
    }
  }
  &:hover{
    background-color: red !important;
   border: 1px solid red !important;
    svg{
      path{
        fill: #fff;
      }
    }
  }

}
.review-icon:hover {
  fill: white !important;
}
.select_skills{
  .react-skill__option{
    &.react-skill__option--is-focused{
      background-color: #fff !important;
    }
    &:hover{
      background-color: #c6e2ff !important;
      
    }
  }
}

.dark-layout{
  .select_skills{
    .react-skill__option{
      &.react-skill__option--is-focused{
        background-color: #0c1c33 !important;
      }
      &:hover{
        background-color: #3498db !important;
        
      }
    }
  }
  .timeline_data{
    color: #fff !important;
  }
  .css-12jo7m5 {
    color: #fff !important;
  }

  input[type="text"]::-webkit-input-placeholder{
    color: #6e6b7b !important;
  }
  .public-DraftStyleDefault-block{
    &>span{
      background-color: transparent !important;
    }
  }
}
 .navigation > li ul .active  .active_icon{
  fill: #fff !important;
  .custom-datepicker .ant-picker-input input::placeholder {
    color: #6e6b7b !important;
  }
  .ant-picker{
    border-color: #fff !important;
    color: #fff !important;
  }
  .ant-picker {
    &:hover {
      border-color: #b3b3b3 !important;
      outline: none !important;
    }
    &.ant-picker-focused
    {
      border-color: #2683ff !important;
      color: #fff !important;
      outline: none !important;
      .ant-picker-input > input::placeholder {
        color: #fff !important;
      }
      
    }
  }
   .css-1h172fq-control{
    .css-8lh6ne-placeholder{
      color: #fff !important
    }
   }
} .navigation > li ul .active  .active_icon{
  fill: #fff !important
}

.range_piker_date{
  height: 40px;
  border-radius: 5px !important;
  border: 1px solid #98a0ae !important;
}
.dark-layout .range_piker_date {
  background-color: #0c1c33;
  color: #fff;
  border-color: #fff !important;
}
.dark-layout .select_tools{
  .anticon svg {
    fill:rgb(33, 118, 255) !important;
    background-color: #132847 !important;
  
  }
  .select_tag{
    .anticon svg {
      fill: #000 !important;
      background-color: transparent !important;
    
    }
  }
}
.dark-layout .ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #2176ff;
}
.range_date {
  width: 100% !important;
  height: 40px !important;
  border-radius: 4px !important;
}
.dark-layout  {
  .range_date:focus,
  .range_date:focus-visible {
    border: 1px solid #40a9ff !important;
  }  
}.dark-layout{

 .select_tools .select_tag .anticon svg {
  fill: #fff !important
 }
 .ant-picker{
  background-color: transparent !important;
  background: transparent !important;
 }
 .ant-picker-clear{
 color: #2176ff;
 }
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  color: #fff !important;
}
.ant-select-selection-overflow {
  row-gap: 3px;
  padding-top: 5px;
}
.select_tools.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  overflow: scroll;
}
 
}.dark-layout{
  .resources-select-container .resources-select__control .resources-select__value-container .resources-select__multi-value .resources-select__multi-value__label {
    color: #fff !important;
  }
  .tech-select-container .tech-select__control .tech-select__value-container .tech-select__multi-value .tech-select__multi-value__label {
    color: #fff !important;
  }
}
.description-error{
  position: absolute;
  bottom: -22px;
  left: 15;
  ;
}
.dark-layout {
  .ant-picker-range-arrow:before{
    background: transparent !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: #7cb3ff !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
    background: #7cb3ff !important;
  }
}
.dark-layout .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: #fff !important;
}
.dark-layout .ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #3498db;
}
.dark-layout .modal .modal-content {
 box-shadow: none;
}
.dark-layout{
    .ant-table-row-expand-icon{
      background: green !important;
    }
  .ant-table-row-expand-icon {
    border-color: green !important;
    background: #283046 !important;
    &[aria-expanded='true'] {
      border-color: red !important;
      background: #283046 !important;
      &:before {
        background: red !important;
      }
      &:after {
        background: red !important;
      }
    }
    &:before {
      background: green !important;
    }
    &:after {
      background: green !important;
    }
  }
}
.select-error {
  bottom: 0px !important;
  left: 0px !important;
}
.ant-timeline-item-head-custom{
  border-radius: 6px !important;
  padding: 6px 6px !important;
  background: #1890ff !important;
}
.ant-timeline-item-head{
    background-color: #1890ff !important;
}
.attachment-input{
  width: calc(100% - 17px) !important;
}
@media screen and (max-width: 991px) {
  .attachment-input{
    width: 100% !important;
  }
}
.dark-layout .resourcesDataTable .css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}
.dark-layout .resourcesDataTable .css-1okebmr-indicatorSeparator {
  background-color: #fff !important; 
}
.el_mg {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}
.labelAsterisk{
  color: #f64c4c !important;
}
.navigatin-list{
  fill: rgb(98.0482608696, 95.3742173913, 109.6357826087) !important;
}
.dark-layout .navigatin-list{
  fill: #ffffff !important;
}